<template>
  <div>
    <section>
      <v-row class="p-5 ml-15 mr-15">
        <div class="text-center pb-5">
          <h3 class="section-subtitle section3-title">Contact to Company</h3>
        </div>
        <v-col sm="5">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              label="First Name"
              solo
              class="pb-3"
              v-model="first_name"
              :rules="fnameRules"
                @keydown="msg=false"
            ></v-text-field>
            <v-text-field
              label="Last Name"
              solo
              id="lname"
              class="pb-3"
              v-model="last_name"
              :rules="lnameRules"
                @keydown="msg=false"
            ></v-text-field>

            <v-text-field
              label="Email"
              solo
              id="email"
              class="pb-3"
              v-model="email"
              :rules="emailRules"
                @keydown="msg=false"
            ></v-text-field>

            <v-text-field
              label="Subject"
              solo
              id="Subject"
              class="pb-3"
              v-model="subject"
              :rules="subjectRules"
                @keydown="msg=false"
            ></v-text-field>

            <v-textarea
              label="Massege"
              solo
              name="input-7-4"
              v-model="message"
              :rules="messageRules"
                @keydown="msg=false"
            ></v-textarea>

            <b-button
              class="button-bg-dark border-radius-8"
              v-on:click="sendData()"
            >
              Submit
            </b-button>
            <div class="mt-3 alert alert-success alert-dismissible fade show" v-if="msg">
        <strong>Success!</strong> Your data has been sent successfully.
    </div>
          </v-form>
        </v-col>
        <v-col sm="1"></v-col>
        <v-col sm="6" class="pt-5">
          <img src="/image/contact.png" class="img" />
        </v-col>
      </v-row>
    </section>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "contact-company",
  data() {
    return {
      valid: true,
      first_name: null,
      last_name: null,
      email: null,
      subject: null,
      message: null,
      msg: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      lnameRules: [(v) => !!v || "LastName is required"],
      fnameRules: [(v) => !!v || "FirstName is required"],
      subjectRules: [(v) => !!v || "Subject is required"],
      messageRules: [(v) => !!v || "Message is required"],
    };
  },
  mounted() {
   
  },
  methods: {
    ...mapActions(["sendCompanyContactUsData"]),
    sendData() {
      if (this.$refs.form.validate()) {
        this.sendCompanyContactUsData({
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          subject: this.subject,
          message: this.message,
          company_id: this.$route.params.id,
        }).then((res) => {
          console.log(res);
          this.msg = true;
          this.first_name = null;
          this.last_name = null;
          this.email = null;
          this.subject = null;
          this.message = null;

          this.$refs.form.resetValidation();
        });
      } else {
        this.$refs.form.validate();
      }
    },
  },
};
</script>
<style scoped>
.img {
  width: 100%;
}
</style>
